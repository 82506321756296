import { FC, Suspense } from 'react'
import routerArr from './router'
import { Routes, Route, Navigate } from "react-router-dom"

const MyRoute = (items: {}[]): JSX.Element[] => {
  return items.map((item: any, key) => {
    return (
      <Route key={key} path={item.path} element={<item.element />} >
        {item.children && MyRoute(item.children)}
      </Route>
    )
  })
}

const Index: FC = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        {MyRoute(routerArr)}
        <Route path="*" element={<Navigate to="/default" />} ></Route>
      </Routes>
    </Suspense>
  )
}

export default Index