import enUS from '@assets/locales/en-US.json';
import zhCN from '@assets/locales/zh-CN.json';

export interface LocaleItem {
  label: string;
  value: string;
  messages: any;
}
export const LOCALES_LIST: Array<LocaleItem> = [
  {
    label: 'English',
    value: 'en-US',
    messages: enUS,
  },
  {
    label: '简体中文',
    value: 'zh-CN',
    messages: zhCN,
  },
  // {
  //   label: "繁體中文",
  //   value: "zh-TW"
  // },
  // {
  //   label: "français",
  //   value: "fr-FR"
  // },
  // {
  //   label: "日本語",
  //   value: "ja-JP"
  // }
];

const transArr2Map = (array: Array<any>, key: string, value: string) => {
  const map: Record<string, string> = {};
  array.forEach(item => {
    map[item[key]] = item[value];
  });
  return map;
};

export const LOCALE_DATA = transArr2Map(LOCALES_LIST, 'value', 'messages');

// {en-US: 'English', zh-CN: '简体中文'}
export const LOCALE_LABEL = transArr2Map(LOCALES_LIST, 'value', 'label');
