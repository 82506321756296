/**
 * @description 封装axios
 */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  getUrlParams,
  DecryptData,
  EncryptData,
  getRandomID,
  local,
  rsaDecrypt,
  isValidJSON,
} from "@/utils/fun";
import { PLATFORM, SERVER_PUBLIC_KEY } from "@/config/constants";
import { useSelector } from "react-redux";

const whitelist = ["/quote", "goerli.infura", "api.etherscan", "/auth", "/operate/event"];

class HttpRequest {
  private readonly baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {},
      withCredentials: false,
    };
    return config;
  }

  addToken(config: AxiosRequestConfig) {
    const token = local.get("token");

    if (token && !navigator.userAgent.match(/.*freechat.*/)) {  // H5页面在页面加载后各自单独传端上的token
      config.headers = Object.assign(
        {
          token,
        },
        config.headers || {}
      );
    }
    return config;
  }

  interceptors(instance: AxiosInstance, url: string | number | undefined) {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const operationID = getRandomID();

        if (config.method === "post") {
          // 判断是否在免除名单
          const white = whitelist.some(
            (item) => config.url?.indexOf(item) !== -1
          );
          // 判断环境 & 获取密钥 & 生成密钥
          if (!white) {
            // 先提取原生data
            let orgData = JSON.parse(config.data);
            // 添加header
            config.headers = Object.assign(
              {
                // 版本号
                "app-version": "1.4.2",
              },
              config.headers
            );
            // 以上传apikey为准
            let apikey = orgData.apiKey || local.get("apikey");
            // console.log(EncryptData(JSON.stringify({"operationID":"1683256969738854","content":"123","images":[]}), apikey));

            if (orgData) {
              let userId = getUrlParams(location.href, "address");
              // 上报接口单独处理
              if (config.url?.indexOf("/auth/api_key") !== -1) {
                // EncryptData(JSON.stringify(orgData), apikey);
                // 加密
                const encryptApikey = rsaDecrypt(orgData.apiKey);
                delete orgData.apiKey;
                config.data = {
                  userId,
                  operationID,
                  platform: PLATFORM,
                  apiKey: encryptApikey,
                };
              } else {
                // 原始data加密
                const cryptData = EncryptData(JSON.stringify(orgData), apikey);
                // 新data结构生成
                config.data = {
                  userId,
                  operationID,
                  platform: PLATFORM,
                  signData: cryptData,
                };
              }
            }
          }
        }
        const resConfig = this.addToken(config);
        return resConfig;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        // post相应拦截
        if (res.config.method === "post") {
          const white = whitelist.some(
            (item) => res.config?.url?.indexOf(item) !== -1
          );
            console.log(res.data)
            // 判断环境 & 获取密钥 & 生成密钥
          if (!white) {
            const orgData = res.data.data;
            if (orgData) {
              let apikey = local.get("apikey");

              const stringData = DecryptData(orgData, apikey);
              console.log(stringData)
              const validJson = isValidJSON(stringData);
              if (validJson) {
                res.data.data = JSON.parse(stringData);
              } else {
                res.data.data = stringData;
              }
            }
          }
        }

        const data = res.data;
        return data;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }

  request(options: AxiosRequestConfig) {
    const instance = axios.create();
    options = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, options.url);
    return instance(options);
  }
}

export default HttpRequest;
