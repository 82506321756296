export const JSON_RPC_URL = "https://cloudflare-eth.com";

export enum LoginTypeMap {
  REGISTER = "register",
  LOGIN = "login",
}

export enum VerifyState {
  INPUT_EMAIL = "inputEmail",
  VERIFY_CODE = "verifyCode",
}

export const KEYCODE = Object.freeze({
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  END: 35,
  HOME: 36,
  SPACE: 32,
  BACK_SPACE: 8,
});

export enum ApiCode {
  SUCCESS = "0",
}

export enum AuthCode {
  NoError = 0,
  FormattingError = 10001,
  HasRegistered = 10002,
  NotRegistered = 10003,
  PasswordErr = 10004,
  GetIMTokenErr = 10005,
  RepeatSendCode = 10006,
  MailSendCodeErr = 10007,
  SmsSendCodeErr = 10008,
  CodeInvalidOrExpired = 10009,
  RegisterFailed = 10010,
  ResetPasswordFailed = 10011,
  DatabaseError = 10002,
  ServerError = 10004,
  HttpError = 10005,
  IoError = 10006,
  IntentionalError = 10007,
}

export enum VerifyAccount {
  HasRegistered = 10002,
  HaventRegistered = 0,
}

export interface blindBoxInfo {
  priceEth: any;
  imgUrl: string;
  name: string;
  probability: string;
  saled: number;
  total: number;
  priceFcc: number;
  priceUsd: number;
}

export interface ownedNftInfo {
  id?: number;
  compress_url: string;
  un_compress_url?: string;
  opensea_gif_url?: string;
  s3_gif_url?: string;
  name: string;
  last_sale: {
    total_price?: string;
    payment_token: {
      usd_price: string;
      eth_price: string;
    };
  };
}

export interface blindBoxOrderNftInfo {
  nft_name: string;
  img_url?: string;
  imgUrl?: string;
  price_usd: number;
  price_fcc: number;
  price_eth?: number;
  quantity?: number;
  total_price?: number;
}

export interface blindBoxOrderInfo {
  box_id: number;
  buy_num: number;
  amount: number;
  is_win: number;
  win_num: number;
  create_time: number;
  nft_info: blindBoxOrderNftInfo[]|ownedNftInfo[];
}

export const ReceiptAccount = "0x9FD16B026A4DcF3A846b1Eb857658C8b307678a2";
export const FccReceiptAccount = "0x4163065497070ea4C95475C81E819249cc4DE167";

export enum NftListTypes {
  default = 1,
}

export enum LANGUAGE {
  USD = "USD",
  CNY = "CNY"
}
export const WINPAGE_TABLIST = [
  {
    key: "stake",
    name: "STAKE.TAB",
  },
  {
    key: "blind-box",
    name: "BLINDBOX.MAIN_TAB",
  },
];

export const PLATFORM = 3;

export const SERVER_PUBLIC_KEY = process.env.NODE_ENV === "development" ?
'-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyjFHa1Pyd0F5ACvqAMRZ/yHz65WPWjpkTV2PtMPFr8ZtHNhr2B8eH4E0XIuoXlHM40MrDCIxpngZeMjVj46Fxha9FK9QXCdh8F9wNh7z9BwZof1gfT58rTLIXuYdrAgUze3PSDg4jNNlB3AreTjVBZswjHQB/w+jLE9sxp+0MZAcmbNGxCQvNsiji63sNziIBb7Au1OsqDydMX6CqK1UsGgfBvgqb7ulLtY+m1+ASO8QElxyRDHCB1l5Pe5yu9zxXc2Ya5aT4njCtlT+dg3yk5T4rAm42jUxzbv+C7N17KOMUhSuMQyf/WHbDHjUnryX8n7niU1nQ+waQYybkDHtFQIDAQAB-----END PUBLIC KEY-----'
: '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs+MEAI4tKUHyLM+RlZqNSxHYVT54MAmlkuTGdvsKTupuRncrFj51uaPq5pOF9NrVfpiJNQ1esEPVjM0LzsxV8gRMeRZzkPCgRCXqe3JEMqKNDHICzA3s3ML7H6J6r2oTHOQLRG0SIiSCqyij7WX5rNqF1Y+vcQAKUITfkhY9NhT0Tb3WlF35xWI6zDZXifuaWmS8He2WdkLOX3r1TapTGPn4o9O0XowsSPkVlPOZvCeuBymDeAvUyA0XiSayxgnYHRkjXrcQccr4L3c39xZRkjiOH1pTkHFSsE5gDi/139PR8fXKhcX/21qTWWTvkg/1kp30aQU4aiVNa+Pj781YTwIDAQAB-----END PUBLIC KEY-----'
