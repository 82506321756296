import { configureStore } from "@reduxjs/toolkit";
import ethereumStore from './modules/ethereumStore'
import header from './modules/header'

export const store = configureStore({
  reducer: {
    ethereumStore,
    header
  }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch