import { createSlice } from "@reduxjs/toolkit";
import { } from '@/utils/fun'
export interface LoginState {
  header: number
}

const initialState: LoginState = {
  header: 0,
}

export const loginSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    addHeader: (state, action) => {
      state.header = action.payload
    },
  }
})

export const { addHeader } = loginSlice.actions

export default loginSlice.reducer