import { Outlet } from 'react-router-dom';
import AppRouter from './router/appRouter';
// import "./assets/scss/global.scss";
import { useEffect, useState } from 'react';
import { LocaleItem, LOCALE_DATA } from './components/const';
import intl from 'react-intl-universal';
import useForceUpdate from 'use-force-update';
import storage from '@/utils/storage';
import { initApikey } from './utils/fun';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalContext } from './Context';

const KEY_LOCALE = 'lang';

function App() {
  const forceUpdate = useForceUpdate();
  const [initDone, setInitDone] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [curLocale, setCurLocale] = useState('');
  const dispatch = useDispatch();

  const setCurrentLocale = (currentLocale: string) => {
    setCurLocale(currentLocale);
    storage.set(KEY_LOCALE, currentLocale);
    intl.init({
      currentLocale,
      locales: LOCALE_DATA,
    });
  };
  const initIntl = () => {
    setCurrentLocale(
      storage.get(KEY_LOCALE) || navigator.language.includes('zh')
        ? 'zh-CN'
        : 'en-US'
    );
    setInitDone(true);
  };

  const pubKey = useSelector(
    (state: { ethereumStore: { ethereumAddressKey: string } }) =>
      state.ethereumStore.ethereumAddressKey
  );
  const privateAddress = useSelector(
    (state: { ethereumStore: { ethereumPriAddressKey: string } }) =>
      state.ethereumStore.ethereumPriAddressKey
  );
  const handleLocaleChange = ({ value }: LocaleItem) => {
    // storage.set(KEY_LOCALE, value);
    // setCurrentLocale(value);
    // forceUpdate();
  };

  useEffect(() => {
    initIntl();
    // const token = local.get("token");
    // const apikey = local.get("apikey");
    // const expiredTime = local.get("expiredTime");
    if (
      !navigator.userAgent.match(/.*freechat.*/) &&
      !location.hash.toLowerCase().includes('#/airdrop')
    ) {
      // H5页面在页面加载后各自通过initApikey上报
      initApikey(pubKey);
    }

    // if (!token || new Date().getTime() > (expiredTime * 1000)) {
    //   dispatch(clearAll());
    // }
    // 端内H5暂时用同一个模板，兼容端内H5
    if (
      !!navigator.userAgent.match(/.*freechat.*/) ||
      location.hash.toLowerCase().includes('#/airdrop')
    ) {
      setHideHeader(true);
    }
  }, []);

  if (!initDone) return null;
  return (
    <div className='overview'>
      <GlobalContext.Provider
        value={{ lang: curLocale, localeChange: setCurrentLocale }}
      >
        {/* {!hideHeader && <Header locale={curLocale} handleLocaleChange={handleLocaleChange} />} */}
        <AppRouter />
        <Outlet />
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
