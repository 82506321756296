import axios from "@utils/auth.request";
import { getRandomID, rsaDecrypt } from "@utils/fun";

interface VerifyExistData {
  email: string;
}
interface VerifyRegsiterData {
  userID: string;
}
interface SendVerifyEmailCodeData {
  userID: string;
  email: string;
}

interface verifyEmailCodeData {
  userID: string;
  email: string;
  verificationCode: string;
  operationID?: string;
  areaCode?: string;
  phoneNumber?: string;
  platform?: number;
}

const PLATFORM = 3;

export interface VerifyResData {
  errCode: number;
  errMsg: string;
}

export const verifyExistEmail = (data: VerifyExistData) => {
  const uuid = getRandomID();
  const verifyData = Object.assign(
    {
      operationID: uuid,
    },
    data
  );

  return axios.request({
    url: `/auth/verify_email`,
    method: "POST",
    data: JSON.stringify(verifyData),
  });
};

export const verifyRegisterEmail = (data: VerifyRegsiterData) => {
  const uuid = getRandomID();
  const verifyData = Object.assign(
    {
      operationID: uuid,
    },
    data
  );

  return axios.request({
    url: `/auth/verify_account`,
    method: "POST",
    data: JSON.stringify(verifyData),
  });
};

export const sendVerifyEmailCode = (
  data: SendVerifyEmailCodeData,
  usedFor: number
) => {
  const uuid = getRandomID();
  const verifyData = Object.assign(
    {
      usedFor,
      operationID: uuid,
    },
    data
  );

  return axios.request({
    url: `/auth/code`,
    method: "POST",
    data: JSON.stringify(verifyData),
  });
};

export const verifyEmailCode = (data: verifyEmailCodeData) => {
  const uuid = getRandomID();
  const verifyData = Object.assign(
    {
      operationID: uuid,
      platform: PLATFORM,
    },
    data
  );

  return axios.request({
    url: `/auth/password`,
    method: "POST",
    data: JSON.stringify(verifyData),
  });
};

export const loginVerifyEmailCode = (data: verifyEmailCodeData) => {
  const uuid = getRandomID();
  const verifyData = Object.assign(
    {
      operationID: uuid,
      platform: PLATFORM,
      usedFor: 3,
    },
    data
  );

  return axios.request({
    url: `/auth/login`,
    method: "POST",
    data: JSON.stringify(verifyData),
  });
};

export const uploadApikey = ({ apikey, userId }) => {
  const uuid = getRandomID();
  const data = {
    apiKey: rsaDecrypt(apikey),
    userId,
    platform: PLATFORM,
    operationID: uuid,
  };
  return axios.request({
    url: `/auth/api_key`,
    method: "POST",
    data: JSON.stringify(data),
  });
};
