import { lazy } from "react";

interface routerItem {
  path: string;
  title: string;
  element: Function;
  children?: Array<{
    path: string;
    title: string;
    element?: Function;
  }>;
}

const routerArr: routerItem[] = [
  {
    path: '/',
    title: '首页',
    element: lazy(() => import('../views/home')),
  },
  {
    path: '/fdao',
    title: 'FDao自治社区',
    element: lazy(() => import('../views/fdao')),
    children: [
      {
        path: '',
        title: '社区首页',
        element: lazy(() => import('../components/community/HomePage')),
      },
      {
        path: '/fdao/fccexchange',
        title: 'FCC兑换',
        element: lazy(() => import('../components/community/FccEquityToken')),
      },
      {
        path: '/fdao/operation',
        title: '运营数据',
        element: lazy(() => import('../views/fdao/operateData')),
      },
      {
        path: '/fdao/developmentroute',
        title: '发展路线',
        element: lazy(() => import('../views/fdao/developmentRoute')),
      },
    ],
  },
  {
    path: '/win',
    title: '赚币',
    element: lazy(() => import('../views/NftBlindBox/index')),
  },
  {
    path: '/win/blind-box',
    title: '盲盒抽奖',
    element: lazy(() => import('../views/NftBlindBox/index')),
  },
  {
    path: '/win/stake',
    title: '质押',
    element: lazy(() => import('../views/stake/index')),
  },
  {
    path: '/usercenter',
    title: '',
    element: lazy(() => import('../views/usercenter')),
  },
  {
    path: '/about',
    title: '关于我们',
    element: lazy(() => import('../views/about')),
  },
  {
    path: '/default',
    title: '',
    element: lazy(() => import('../views/404')),
  },
  {
    path: '/native/share',
    title: '分享',
    element: lazy(() => import('../views/nativeH5/share/App')),
  },
  {
    path: '/share',
    title: '分享',
    element: lazy(() => import('../views/share/App')),
  },
  {
    path: '/groupshare',
    title: '分享',
    element: lazy(() => import('../views/groupshare/App')),
  },
  {
    path: 'airdrop',
    title: '空投活动',
    element: lazy(() => import('../views/airdrop')),
  },
  // {
  //   path: 'advertising',
  //   title: '广告投放',
  //   element: lazy(() => import('../views/404')),
  // }
];

export default routerArr;
