// import "./assets/scss/global.scss";
import { createContext } from 'react';
import storage from '@/utils/storage';

const KEY_LOCALE = 'lang';

export const GlobalContext = createContext({
  lang: navigator.language.includes('zh') ? 'zh-CN' : 'en-US',
  localeChange: value => {
    storage.set(KEY_LOCALE, value);
  },
});
