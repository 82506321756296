import { createSlice } from "@reduxjs/toolkit";
import { local,session } from '@/utils/fun'

export interface Record {
  addr: string;
  time: number;
}

export interface LoginState {
  ethereumList: string;
  ethereumAddressKey: string;
  ethereumPriAddressKey: string;
  storeTransferAddress: Record[],
  CNY: string,
  isUnit: string,
  encodeMnemonic: string,
  backupMnemonic: boolean,
  setIsNewRegister: boolean,
}

const initialState: LoginState = {
  ethereumList: local.get('EthereumList') ? local.get('EthereumList') : "",
  ethereumAddressKey: local.get('EthereumAddressKey') ? local.get('EthereumAddressKey') : "",
  ethereumPriAddressKey: local.get('EthereumPriAddressKey') ? local.get('EthereumPriAddressKey') : "",
  storeTransferAddress: local.get('TransferAddress') ? local.get('TransferAddress') : [],
  CNY: local.get('CNY') ? local.get('CNY') : '0',
  isUnit: session.get('unit') ? session.get('unit') : 'USD',
  encodeMnemonic: local.get('EncodeMnemonic') || "",
  backupMnemonic: local.get('BackupMnemonic') || false,
  setIsNewRegister: local.get('isNewRegister') || false,
}

export const loginSlice = createSlice({
  name: 'eth',
  initialState,
  reducers: {
    addEthereumList: (state, action) => { // 助记词
      state.ethereumList = action.payload
      local.set('EthereumList', action.payload)
    },
    addEthereumAddressKey: (state, action) => {
      state.ethereumAddressKey = action.payload
      local.set('EthereumAddressKey', action.payload)
    },
    addEthereumPriAddressKey: (state, action) => {
      state.ethereumPriAddressKey = action.payload
      local.set('EthereumPriAddressKey', action.payload)
    },
    addTransferAddress: (state, action) => {
      state.storeTransferAddress = action.payload
      local.set('TransferAddress', action.payload)
    },
    addCNY: (state, action) => {
      state.CNY = action.payload
      local.set('CNY', action.payload)
    },
    addIsUnit: (state, action) => {
      state.isUnit = action.payload
      local.set('unit', action.payload)
    },
    removeLogin: (state) => {
      state.ethereumAddressKey = ''
      state.ethereumPriAddressKey = ''
      local.remove('EthereumPriAddressKey')
      local.remove('EthereumAddressKey')
    },
    clearAll: (state) => {
      state.ethereumList = ''
      state.ethereumAddressKey = ''
      state.ethereumPriAddressKey = ''
      local.clear()
    },
    addEncodeMnemonic: (state, action) => {
      state.encodeMnemonic = action.payload
      local.set('EncodeMnemonic', action.payload)
    },
    setBackupMnemonic: (state, action) => {
      state.backupMnemonic = action.payload
      local.set('BackupMnemonic', action.payload)
    },
    setIsNewRegister: (state, action) => {
      state.backupMnemonic = action.payload
      local.set('isNewRegister', action.payload)
    },
  }
})

export const { addEthereumList, addCNY,addIsUnit, addEthereumAddressKey, removeLogin, clearAll,
  addEthereumPriAddressKey, addTransferAddress, addEncodeMnemonic, setBackupMnemonic, setIsNewRegister } = loginSlice.actions

export default loginSlice.reducer